﻿<script>
  let activeTab = "Overview";
</script>

<style>
  @import url("https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css");
</style>

<div class="min-h-screen bg-black text-gray-300">
  <!-- Top Bar -->
  <header class="bg-blue-700 text-white py-4 shadow-lg">
    <div class="container mx-auto flex justify-between items-center px-6">
      <h1 class="text-3xl font-extrabold">DomuV</h1>
      <nav>
        <ul class="flex space-x-6">
          <li>
            <button 
              class="px-6 py-2 bg-blue-600 rounded-full text-white font-semibold shadow-md transition-transform transform hover:scale-105 hover:bg-blue-500 focus:ring focus:ring-blue-300"
              on:click={() => (activeTab = 'Overview')}
            >
              Übersicht
            </button>
          </li>
          <li>
            <button 
              class="px-6 py-2 bg-blue-600 rounded-full text-white font-semibold shadow-md transition-transform transform hover:scale-105 hover:bg-blue-500 focus:ring focus:ring-blue-300"
              on:click={() => (activeTab = 'Download')}
            >
              Download
            </button>
          </li>
          <li>
            <button 
              class="px-6 py-2 bg-blue-600 rounded-full text-white font-semibold shadow-md transition-transform transform hover:scale-105 hover:bg-blue-500 focus:ring focus:ring-blue-300"
              on:click={() => (activeTab = 'ÜberUns')}
            >
              Über uns
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </header>

  <!-- Content -->
  <main class="container mx-auto py-12 px-6">
    {#if activeTab === 'Overview'}
      <section class="rounded-lg bg-gray-800 p-8 shadow-md">
        <h2 class="text-4xl font-bold mb-6 text-blue-300">Übersicht</h2>
        <p class="text-lg">Willkommen bei DomuV, Ihrem Partner für innovative Lösungen. Erfahren Sie mehr über unsere Vision und Leistungen.</p>
      </section>
    {/if}

    {#if activeTab === 'Download'}
      <section class="rounded-lg bg-gray-800 p-8 shadow-md">
        <h2 class="text-4xl font-bold mb-6 text-blue-300">Download</h2>
        <p class="text-lg">Laden Sie die neueste Version von DomuV herunter und erleben Sie die Zukunft.</p>
        <a href="/impressum" class="mt-6 inline-block px-8 py-4 bg-blue-600 text-white font-bold rounded-lg shadow-lg hover:bg-blue-500">Herunterladen</a>
      </section>
    {/if}

    {#if activeTab === 'ÜberUns'}
      <section class="rounded-lg bg-gray-800 p-8 shadow-md min-h-screen">
        <h2 class="text-4xl font-bold mb-6 text-blue-300">Über uns</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div class="bg-gray-700 rounded-lg p-6 shadow-lg">
            <h3 class="text-2xl font-bold text-blue-300 mb-4">Unsere Geschichte</h3>
            <p class="text-lg">Erfahren Sie mehr über die Ursprünge und das Wachstum von DomuV.</p>
          </div>
          <div class="bg-gray-700 rounded-lg p-6 shadow-lg">
            <h3 class="text-2xl font-bold text-blue-300 mb-4">Kontakt</h3>
            <p class="text-lg">Haben Sie Fragen? Wir sind hier, um Ihnen zu helfen!</p>
          </div>
          <div class="bg-gray-700 rounded-lg p-6 shadow-lg">
            <h3 class="text-2xl font-bold text-blue-300 mb-4">Impressum</h3>
            <p class="text-lg">Alle rechtlichen Informationen zu DomuV an einem Ort.</p>
          </div>
        </div>
      </section>
    {/if}
  </main>

  <footer class="bg-gray-900 text-gray-400 py-6">
    <div class="container mx-auto text-center">
      <p class="text-sm">&copy; 2024 DomuV. Alle Rechte vorbehalten.</p>
    </div>
  </footer>
</div>
